(function($) {
  'use strict';
  //Open submenu on hover in compact sidebar mode and horizontal menu mode
  $(document).on('mouseenter mouseleave', '.sidebar', function(ev) {
    var body = $('body');
    body[0].classList.remove('dx-device-desktop');
    body[0].classList.remove('dx-device-generic');
    var sidebarIconOnly = body.hasClass("sidebar-icon-only");
    var sidebarFixed = body.hasClass("sidebar-fixed");
    var sidebarToggleClass = body.hasClass("toggleCustom");

    const bodyClassList = body.attr('class')?.split(' ');
    const mainPanel = document.getElementById('main-panel-control');
    // toggleCustom swal2-toast-shown swal2-shown  sidebar-icon-only

    if((!bodyClassList?.includes("swal2-toast-shown") && !bodyClassList?.includes("swal2-shown"))|| bodyClassList?.includes("toggleCustom")){
      if((bodyClassList?.length === 1 || bodyClassList?.length === 3) && bodyClassList?.includes("toggleCustom")){
        mainPanel.classList.add('sidebar-full-main-panel-margin');
        mainPanel.classList.remove('sidebar-icon-only-main-panel-margin');
      } else if(bodyClassList?.length > 1) {
        mainPanel.classList.add('sidebar-icon-only-main-panel-margin');
        mainPanel.classList.remove('sidebar-full-main-panel-margin');
      }
    }
    

    if (!('ontouchstart' in document.documentElement)) {
      if (sidebarToggleClass) {
        if (sidebarFixed) {
          if (ev.type === 'mouseenter') {
            body.removeClass('sidebar-icon-only');
          }
        } else {
          var $menuItem = $(this);
          if (ev.type === 'mouseenter') {
            // $menuItem.addClass('hover-open')
            body.removeClass('sidebar-icon-only')
            // if ((body.hasClass('sidebar-toggle-display')) || (body.hasClass('sidebar-absolute'))) {
            //   body.toggleClass('sidebar-hidden');
            // } else {
            //   body.toggleClass('sidebar-icon-only');
            // }

            var crossIcon = document.getElementsByClassName('menuToggleCrossIcon')[0]; 
            crossIcon.style.left= '48px'
          } else {
            //$menuItem.removeClass('hover-open')
            body.addClass('sidebar-icon-only')
            let crossIcon = document.getElementsByClassName('menuToggleCrossIcon')[0]; 
            crossIcon.style.left =  '-203px'
            // if ((body.hasClass('sidebar-toggle-display')) || (body.hasClass('sidebar-absolute'))) {
            //   body.toggleClass('sidebar-hidden');
            // } else {
            //   body.toggleClass('sidebar-icon-only');
            // }
          }
        }
      }
    }
  });
})(jQuery);
